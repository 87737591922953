// extracted by mini-css-extract-plugin
export var arrows = "wristo-module--arrows--ee198";
export var at1024__pr50p = "wristo-module--at1024__pr50p--19855";
export var at640__pr50p = "wristo-module--at640__pr50p--7f70e";
export var at768__w50p = "wristo-module--at768__w50p--8be45";
export var bg__nobleGray = "wristo-module--bg__nobleGray--c9f75";
export var billetShadow = "wristo-module--billet-shadow--c7188";
export var block = "wristo-module--block--31d17";
export var blockColor0Wristo = "wristo-module--blockColor0Wristo--8dc1a";
export var blockColor1Wristo = "wristo-module--blockColor1Wristo--73670";
export var blockColor2Wristo = "wristo-module--blockColor2Wristo--b7ba6";
export var blockColor3Wristo = "wristo-module--blockColor3Wristo--168bf";
export var blockText = "wristo-module--blockText--1d6ea";
export var blockTitles = "wristo-module--blockTitles--d8e76";
export var brline = "wristo-module--brline--a3d2d";
export var btn = "wristo-module--btn--0ab03";
export var c = "wristo-module--c--c7ff3";
export var cc18sf = "wristo-module--cc-18sf--1c527";
export var cc199t = "wristo-module--cc-199t--34dee";
export var cc1ada = "wristo-module--cc-1ada--2ae02";
export var cc1aev = "wristo-module--cc-1aev--3bb74";
export var cc1lut = "wristo-module--cc-1lut--a77a1";
export var cc1xry = "wristo-module--cc-1xry--433e0";
export var cc2hzo = "wristo-module--cc-2hzo--d6c07";
export var cc2n8m = "wristo-module--cc-2n8m--444d8";
export var cc4xbu = "wristo-module--cc-4xbu--1dde0";
export var ccCaj5 = "wristo-module--cc-caj5--e1c65";
export var ccEw5j = "wristo-module--cc-ew5j--9deb8";
export var ccG73w = "wristo-module--cc-g73w--4f515";
export var ccKgeu = "wristo-module--cc-kgeu--6e32f";
export var ccKv6t = "wristo-module--cc-kv6t--edddd";
export var ccPjpe = "wristo-module--cc-pjpe--c45cd";
export var ccUnoo = "wristo-module--cc-unoo--8e9b7";
export var childAs__seeMore = "wristo-module--childAs__seeMore--d3949";
export var circle = "wristo-module--circle--975b1";
export var circles = "wristo-module--circles--19a5b";
export var clearAll = "wristo-module--clearAll--59a46";
export var colorScheme__background__grayPale = "wristo-module--colorScheme__background__gray-pale--e81e1";
export var colorScheme__buttonGold = "wristo-module--colorScheme__buttonGold--6644b";
export var colorScheme__buttonGold__darkBackground = "wristo-module--colorScheme__buttonGold__darkBackground--b2ba4";
export var colorScheme__linkGold = "wristo-module--colorScheme__linkGold--60963";
export var colorScheme__whiteButton = "wristo-module--colorScheme__whiteButton--458fc";
export var coloredBackground__dark = "wristo-module--coloredBackground__dark--958f5";
export var coloredBackground__light = "wristo-module--coloredBackground__light--80c30";
export var commentBlockWristo = "wristo-module--commentBlockWristo--57a31";
export var commentName = "wristo-module--commentName--cacf0";
export var commentPosition = "wristo-module--commentPosition--20ede";
export var commentText = "wristo-module--commentText--1112b";
export var commentTitle = "wristo-module--commentTitle--80a48";
export var commentTitleWristo = "wristo-module--commentTitleWristo--db8bb";
export var common__article__section = "wristo-module--common__article__section--1bb65";
export var common__article__section__doubleIndentBottom = "wristo-module--common__article__section__doubleIndentBottom--69f51";
export var common__article__section__zeroIndentBottom = "wristo-module--common__article__section__zeroIndentBottom--47255";
export var common__article__section__zeroIndentTop = "wristo-module--common__article__section__zeroIndentTop--b5799";
export var common__chips = "wristo-module--common__chips--9445a";
export var common__headline = "wristo-module--common__headline--ebf2d";
export var common__headline__beveledBottom = "wristo-module--common__headline__beveledBottom--19f95";
export var common__headline__bgLoop = "wristo-module--common__headline__bgLoop--0c11d";
export var common__leadQuote = "wristo-module--common__leadQuote--86dfa";
export var common__projectsTeaser = "wristo-module--common__projects-teaser--55e63";
export var common__scrollableTarget = "wristo-module--common__scrollableTarget--a96f0";
export var common__teaser = "wristo-module--common__teaser--0b9e3";
export var common__teaser__button = "wristo-module--common__teaser__button--83852";
export var common__textOutline = "wristo-module--common__textOutline--340e5";
export var contentMiddle = "wristo-module--contentMiddle--d4011";
export var crispChatbox = "wristo-module--crisp-chatbox--4dec2";
export var crispClient = "wristo-module--crisp-client--d57aa";
export var darkBackground = "wristo-module--darkBackground--42aa9";
export var darkTxt = "wristo-module--darkTxt--f09b0";
export var fatButton = "wristo-module--fatButton--02b95";
export var featuresTitle = "wristo-module--featuresTitle--2a828";
export var first = "wristo-module--first--f7b6f";
export var floatRight = "wristo-module--float-right--24ba8";
export var fs1o5 = "wristo-module--fs1o5--95cc0";
export var fullWidth = "wristo-module--fullWidth--12fa4";
export var goldBackground = "wristo-module--goldBackground--bf9bb";
export var goldButton = "wristo-module--goldButton--d42c6";
export var goldButton__casePorter = "wristo-module--goldButton__casePorter--915a4";
export var goldButton__centered = "wristo-module--goldButton__centered--b8c5b";
export var goldButton__w14 = "wristo-module--goldButton__w14--3ce4b";
export var goldFatLink = "wristo-module--goldFatLink--e51f6";
export var icomoon = "wristo-module--icomoon--5e1f8";
export var l = "wristo-module--l--9f578";
export var last = "wristo-module--last--9001c";
export var lineBreak = "wristo-module--lineBreak--2d120";
export var list = "wristo-module--list--4f3f9";
export var lowercase = "wristo-module--lowercase--b9d23";
export var mainImageWristo = "wristo-module--mainImageWristo--f2207";
export var maxw20 = "wristo-module--maxw20--8f7f0";
export var mb2 = "wristo-module--mb2--f9045";
export var mockups = "wristo-module--mockups--144ac";
export var mt2 = "wristo-module--mt2--41c55";
export var nobr = "wristo-module--nobr--37430";
export var p0 = "wristo-module--p0--ad9ab";
export var pB0 = "wristo-module--p-b-0--156fa";
export var pL0 = "wristo-module--p-l-0--11469";
export var pR0 = "wristo-module--p-r-0--be011";
export var primaryButton = "wristo-module--primaryButton--6cac1";
export var pt2 = "wristo-module--pt2--0c559";
export var r = "wristo-module--r--53fe2";
export var reverse = "wristo-module--reverse--3638f";
export var sectionTitle = "wristo-module--section-title--10630";
export var seeMore = "wristo-module--seeMore--05076";
export var servicesBlock = "wristo-module--servicesBlock--09bd7";
export var showLess = "wristo-module--showLess--2e818";
export var showMore = "wristo-module--showMore--8f136";
export var showMoreLine = "wristo-module--showMoreLine--a0bf6";
export var termsLine = "wristo-module--termsLine--2c1aa";
export var textMargin = "wristo-module--textMargin--c7d29";
export var textOutline = "wristo-module--textOutline--f6bc3";
export var titleLine = "wristo-module--titleLine--ecbc8";
export var titleLineAlign = "wristo-module--titleLineAlign--15143";
export var titleTextWristo = "wristo-module--titleTextWristo--af168";
export var titleWristo = "wristo-module--titleWristo--492d5";
export var transition = "wristo-module--transition--8548c";
export var transitionBackground = "wristo-module--transition-background--ebeac";
export var transitionReverse = "wristo-module--transition-reverse--c000b";
export var underTitleTextWristo = "wristo-module--underTitleTextWristo--d4ca7";
export var upTo2cols = "wristo-module--upTo2cols--93420";
export var upTo2colsImgTxt = "wristo-module--upTo2colsImgTxt--af7dc";
export var uppercase = "wristo-module--uppercase--10f84";
export var v2022 = "wristo-module--v2022--c4741";
export var visitBtnWristo = "wristo-module--visitBtnWristo--0e308";
export var whatIsWristoText = "wristo-module--whatIsWristoText--580d9";
export var whiteButton = "wristo-module--whiteButton--e670d";
export var wristoCarousel = "wristo-module--wristoCarousel--268ad";
export var wristoMock = "wristo-module--wristoMock--b7c3b";
export var wristoText = "wristo-module--wristoText--eaa2b";