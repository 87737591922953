// extracted by mini-css-extract-plugin
export var avif = "styles-module--avif--f7e07";
export var billetShadow = "styles-module--billet-shadow--4cd24";
export var bookLink = "styles-module--book-link--9b4f6";
export var colorScheme__background__grayPale = "styles-module--colorScheme__background__gray-pale--f996c";
export var formContainer = "styles-module--form-container--d0407";
export var formGroup = "styles-module--form-group--ef0ce";
export var formSubtitle = "styles-module--form-subtitle--1b168";
export var formTitle = "styles-module--form-title--ac3eb";
export var icomoon = "styles-module--icomoon--e280e";
export var input = "styles-module--input--2ec9f";
export var laformSection = "styles-module--laform-section--b27f8";
export var laformSectionContainer = "styles-module--laform-section-container--fca09";
export var lowercase = "styles-module--lowercase--f9739";
export var manContainer = "styles-module--man-container--6771f";
export var manContent = "styles-module--man-content--43b66";
export var manLabel = "styles-module--man-label--9f7ea";
export var manLabelContainer = "styles-module--man-label-container--a8e66";
export var manName = "styles-module--man-name--bed8a";
export var manPosition = "styles-module--man-position--2d33a";
export var topDevInnercontainer = "styles-module--top-dev-innercontainer--a4f5e";
export var transition = "styles-module--transition--c50fb";
export var transitionBackground = "styles-module--transition-background--19f75";
export var transitionReverse = "styles-module--transition-reverse--41ee9";
export var uppercase = "styles-module--uppercase--97121";
export var v1 = "styles-module--v1--1e431";
export var v2 = "styles-module--v2--bcfda";