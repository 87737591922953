import React from "react"
import cx from "classnames"
import { Helmet } from "react-helmet"

import * as feedbackStyles from "./styles.module.scss"
import FeedbackForm from "../feedback-form"
import { CALENDLY_URL } from "../../const"

const FeedbackSection = () => (
  <>
    <Helmet>
      <script src="/avif-webp.min.js" />
    </Helmet>
    <div className={feedbackStyles.laformSection} id="formbg-v2">
      <div className={feedbackStyles.laformSectionContainer}>
        <div className={feedbackStyles.formContainer}>
          <h2 className={feedbackStyles.formTitle}>
            We bring ideas to&nbsp;life
          </h2>
          <h3 className={feedbackStyles.formSubtitle}>
            Become our client and get the best services and emotions with us.
          </h3>
          <FeedbackForm formStatID="CommonFooter" />
        </div>
        <div
          className={cx(feedbackStyles.manLabelContainer, feedbackStyles.v2)}
        >
          <div className={feedbackStyles.manLabel}>
            <div className={feedbackStyles.manContent}>
              <h2 className={feedbackStyles.manName}>Solomiia Vuitsyk</h2>
              <h3 className={feedbackStyles.manPosition}>Engagement manager</h3>
              <a
                href={CALENDLY_URL}
                className="seeMore"
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a Meeting
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default FeedbackSection
